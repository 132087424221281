import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';

export const Text = styled('p')`
  color: white;
  line-height: 2;
  white-space: pre-line;
  a {
    color: white;
  }
`;

export const MainHeading = styled('h1')`
  color: white;
`;

export const Heading = styled('h2')`
  color: white;
`;

export const List = styled('ul')``;

export const ListItem = styled('li')`
  color: white;
  line-height: 2;
`;

export const StyledLink = styled(AniLink)`
  color: white;
`;
