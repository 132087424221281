import React from 'react';
import {StyledLink} from './About.styled';

export function renderTextWithLink(input: string): JSX.Element | string {
  try {
    const match = input.match(/\[(.*?)\]\(((.*?).+)\)/i);

    if (!match) {
      return input;
    }

    const text = match[1];
    const link = match[2];

    if (!text || !link) {
      return input;
    }

    const restOfString = input.split(match[0]);

    return (
      <span>
        {restOfString[0]}
        <StyledLink fade={true} to={link}>
          {text}
        </StyledLink>
        {restOfString[1]}
      </span>
    );
  } catch (error) {
    return input;
  }
}
