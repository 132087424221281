export interface Heading {
  type: 'heading' | 'main-heading';
  text: string;
}

export interface List {
  type: 'list';
  items: string[];
}

export interface Text {
  type: 'text';
  text: string;
}

export interface Link {
  type: 'link';
  to: string;
  text: string;
}

export type Data = Array<Heading | Text | List | Link>;

export const rows: Data = [
  {
    type: 'main-heading',
    text: 'Hello, I am Ognen',
  },
  {
    type: 'text',
    text:
      'I am a Frontend developer that resides in Hamburg, 🇩🇪. Although cliché, I love coding, I care for the environment 🌲 and I often hit the gym 💪🏻. This blog started more as a learning project then an actuall portfolio, but I kinda stuck to it.',
  },
  {
    type: 'text',
    text:
      'I am an ENTJ. If you have no idea what that is, I highly recommend getting the <a href="https://www.16personalities.com/" target="_blank" rel="noopener">16personalities</a> test. It will tell you stuff about yourself that even you didn\'t know.',
  },
  {type: 'heading', text: 'Stack'},
  {
    type: 'text',
    text:
      'Since I started my developers path, JavaScript was my go-to-language. As it got more and more popular in the past couple of years, my love for it grew. So my timeline would look something like: ',
  },
  {
    type: 'list',
    items: [
      '<strong>Angular1 + Ionic</strong> building a mobile app for language learning for Wombat Software, a small startup in Cologne.',
      'Again <strong>Angular1 + Ionic</strong> for a mobile app for rating football clubs, for rate-your-club, also a startup in Cologne.',
      '<strong>Laravel (PHP Framework)</strong> for Backend for the startup.',
      '<strong>react-native</strong> yet again for the same startup.',
      '<strong>Jekyll - Static site generator </strong> for InVision AG, a super-nice company in Dusseldorf.',
      '<strong>react-native</strong> for About You GmbH.',
      '<strong>ReactJS</strong> as well for About You GmbH.',
      'Back to <strong>react-native + graphql</strong> at empfohlen.de.',
    ],
  },
  {
    type: 'text',
    text:
      'In the meantime I am also devoting time towards learning more about GraphQL and more of the MERN stack.',
  },
  {
    type: 'link',
    to: '/books',
    text:
      "Any time that I have left, I try to read as much as possible. Lately I'm most into investing and personal-development [books](books).",
  },
];
