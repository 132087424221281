import React from 'react';
import * as Styled from '../components/About/About.styled';
import * as Data from '../components/About/data';
import {renderTextWithLink} from '../components/About/utils';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

export default function about() {
  return (
    <Layout>
      <SEO title="About me" />
      <>
        {Data.rows.map((row, index) => {
          switch (row.type) {
            case 'main-heading':
              return (
                <Styled.MainHeading key={index}>{row.text}</Styled.MainHeading>
              );
            case 'text':
              return (
                <Styled.Text
                  dangerouslySetInnerHTML={{
                    __html: row.text,
                  }}
                  key={index}
                />
              );
            case 'heading':
              return <Styled.Heading key={index}>{row.text}</Styled.Heading>;
            case 'list':
              return (
                <Styled.List>
                  {row.items.map((item, listItemIndex) => (
                    <Styled.ListItem
                      key={listItemIndex}
                      dangerouslySetInnerHTML={{__html: item}}
                    />
                  ))}
                </Styled.List>
              );
            case 'link':
              return <Styled.Text>{renderTextWithLink(row.text)}</Styled.Text>;
          }
        })}
      </>
    </Layout>
  );
}
